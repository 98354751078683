<template>
  <div class="app-container">
    <CrudTable
      entity="Employee"
      :columns="columns"
      :generate-summary="generateSummary"
      :permission-code="'MyEmployees'"
      :default-filters="{ onlineYN: 1 }"
    />
  </div>
</template>

<script>
import CrudTable from '@/components/crud/CrudTable';
import { booleanColumnFilter, booleanColumnFormatter } from '@/utils/filters';
export default {
  components: {
    CrudTable
  },
  data() {
    return {
      columns: [
        { field: 'name' },
        {
          field: 'onlineYN',
          label: 'common.onlineYN',
          filter: booleanColumnFilter(this),
          formatter: booleanColumnFormatter(this)
        }
      ]
    };
  },
  methods: {
    generateSummary({ name }) {
      return `${name}`;
    }
  }
};
</script>
